/**
 * No changes needed here
 */

jQuery(function($) {

  /* initialize FlexNav */

    $(".flexnav").flexNav({
      'calcItemWidths': false,
      'hover': false
    });

  /* clone overview pages into submenu */

	  $('.menu-item-has-children > a').each(function(index, el){

	    if( $(this).parent().hasClass('append-to-sub') ) {

	      var target = $(this).next('ul');
	      var classes = $(this).parent().attr("class");
	      var text = $(this).data("title");

	      $(this).clone().prependTo(target).wrap('<li></li>').text(text).parent().attr("class", classes).removeClass('menu-item-has-children item-with-ul current-page-ancestor current-menu-ancestor current-menu-parent current-page-parent current_page_parent current_page_ancestor append-to-sub');
	      $(this).attr("href", "#");
	    }else{
	      $(this).attr("href", "#");
	    }

	    $(this).on('click', function(e) {
	      e.preventDefault();
	    });

	  });

});
