/**
 * Important Notice:
 *
 * If Scripts like select2, owlCarousel or fancybox are needed,
 * the have to be moved from libs_notUsed to libs. Actually only
 * the flexnav files are in the folder libs.
 */

jQuery(function($) {

  var is_safari = navigator.userAgent.indexOf("Safari") > -1;

  if (is_safari) {
    $(".callToAction .icon").addClass('safari');
  }
    /* front-page slider */

  $(".lpHeader.slider .slider .sliderWrapper").each(function(){
    if($(this).children().length > 1){
      $(this).addClass('owl-carousel owl-theme').owlCarousel({
        nav: false,
        //navText: ['<i class="icon-arrowsliderleft"></i>','<i class="icon-arrowsliderright"></i>'],
        autoplay: true,
        autoHeight: true,
        items: 1,
        smartSpeed: 400,
        dotClass: 'owl-dot',
		dotsClass: 'owl-dots',
		dots: true,
        loop: true,
        navContainer: '.slider-nav',
        responsive : {
          0 : {
            nav: true,
          },
          600 : {
            nav: true,
          },
        }
      });
    }
  });

  /* featureSlider */

  $(".featureSlider .sliderWrapper").each(function(){
    if($(this).children().length > 1){
      $(this).addClass('owl-carousel owl-theme').owlCarousel({
        nav: false,
        navText: ['<i class="icon-arrowsliderleft"></i>','<i class="icon-arrowsliderright"></i>'],
        autoplay: false,
        items: 1,
        smartSpeed: 400,
        dots: true,
        loop: false,
        navContainer: $(this).next('.slider-nav'),
        responsive : {
          0 : {
            nav: false,
          },
          600 : {
            nav: true,
          },
          750 : {
            items: 2,
            nav: false,
          },
          1000 : {
            items: 3,
            nav: false,
          },
          1800 : {
            items: 3,
            nav: true,
          }
        }
      });
    }
  });

    /* featureSlider */

    $(".quoteSlider .sliderWrapper").each(function(){
        if($(this).children().length > 1){
            $(this).addClass('owl-carousel owl-theme').owlCarousel({
                autoplay: false,
                items: 1,
                smartSpeed: 400,
                dots: true,
                loop: false
            });
        }
        });

  /* form select 2 */

  $(".wpcf7 select").select2({
      placeholder: 'Bitte wählen',
      minimumResultsForSearch: Infinity,
      width: "style",
  });


  /* searchForm */

  $('.menu-button, .searchButton, .searchForm .close').on('click', function() {
    if($(".searchForm").hasClass('hide')){
      $(".searchForm").removeClass('hide');
      $(".searchForm").addClass('open');
    }else{
      $(".searchForm").addClass('hide');
      $(".searchForm").removeClass('open');
    }
  });


  /* productForm */
  $(".productForm").hide();
  $('.callToAction.form').on('click', function(e) {
    e.preventDefault();
    $(".productForm").slideToggle();
  });

  /* call-to-action form */
  $(".catalogueForm").hide();
  $('.catalogue .button').on('click', function(e) {
    e.preventDefault();
    $(".catalogueForm").slideToggle();
  });

  /* recruiting form */

  $('input[type=file]').on('change', function() {
    var filename = $(this).val().split('\\').pop();
    if (filename)
      $(this).parent().parent().find('label').html(filename).addClass('selected');
    else
      $(this).parent().parent().find('label').html('').removeClass('selected');
  });


  /* banner home */
  //$(".breadcrumb + .banner").prev().addClass('no-margin');

  /* career */

  if( $("main > *").last().hasClass('career') ) {
    $(".career").last().addClass('no-margin');
  }

  enquire.register("screen and (min-width: 700px)", {

      match : function() {

        $('.hotSpots .product').addClass('hide');
        $('.hotSpots .entry').removeClass('active');

        $(document).on('click touchstart', function (event) {
            if(!$(event.target).closest('.product').length) {
                if($('.hotSpots .entry').hasClass("active")) {
                  $('.hotSpots .entry').removeClass('active');
                  $('.hotSpots .product').addClass('hide');
                }
            }
        });

        $('.hotSpots .product').on('click', function(e) {
            event.stopPropagation();
        });

        $('.hotSpots .number').on('click', function(e) {

          $('.hotSpots .product').addClass('hide');
          $('.hotSpots .entry').removeClass('active');

          $('.hotSpots .entry').addClass('active');
          $(this).closest('.entry').find('.product:eq(' + $(this).index() + ')').removeClass('hide');
          e.stopPropagation();

        });

        $('.hotSpots .close').on('click', function(e) {

          $('.hotSpots .entry').removeClass('active');
          $('.hotSpots .product').addClass('hide');
          e.stopPropagation();

        });

      },

      unmatch : function() {

        $('.hotSpots .product').removeClass('hide');
        $('.hotSpots .number, .hotSpots .products, .hotSpots .close').off('click');

      }

  });

  /* newsOverview */

  $(".newsOverview + .newsOverview").prev().addClass('no-margin');

  /* initialize history slider */

  if ($('.history').length) {

    var timeLine = $('#timeLineSlider .years');
    var history = $('#historyContent .slides');

    $('#timeLineSlider .year').first().addClass('current');

    timeLine.owlCarousel({
      nav: true,
      navText: ['<i class="icon-arrowsliderleft"></i>','<i class="icon-arrowsliderright"></i>'],
      dots:false,
      pullDrag: false,
      touchDrag: false,
      mouseDrag: false,
      center: true,
      linked: '#historyContent .slides',
      responsive : {
        0 : {
          items:1,
        },
        600 : {
          items:3,
        },
        1000 : {
          items:5,
        },
      }
    }).on('click', '.owl-item', function(event) {
      history.trigger('to.owl.carousel', [$(event.target).parents('.owl-item').index(), 500, true]);
    }).on('changed.owl.carousel', function(event) {
        timeLine.find('.current').removeClass('current');
        timeLine.find('.owl-item .year').eq(event.item.index).addClass('current');
    });

    $('.owl-nav').removeClass('disabled');

    history.owlCarousel({
      nav: false,
      dots: false,
      margin: 10,
      items: 1,
      smartSpeed: 400,
      linked: '#timeLineSlider .years'
    }).on('changed.owl.carousel', function(event) {
        timeLine.find('.current').removeClass('current');
        timeLine.find('.owl-item .year').eq(event.item.index).addClass('current');
    });

  }

/*
* productFilter
*/

    var filter = document.querySelector('.filter');

    if (filter) {

        var filterOptions = document.querySelector('.filterOptions');
        var filterReset = document.querySelector('.filterReset');
        var filterButton = document.querySelector('.filterButton');
        var filterForm = document.getElementById('productFilter');
        var formFields = document.querySelectorAll('input');

        function checkFields () {
            var selectedInputs = 0;

            selectedInputs = $("#productFilter :checkbox:checked, #productFilter :radio:checked").length;

            if( selectedInputs === 0 ) {
                filterReset.classList.add('hidden');
                filterButton.innerHTML = filterButton.getAttribute('data-notfiltered');
            }else{
                filterReset.classList.remove('hidden');
                filterButton.innerHTML = filterButton.getAttribute('data-filtered');
            }
        }

        /* initial check if filter reset button should be shown or not */
        checkFields();

        /* hide or show the filter panel */
        $('.filterToggle').on('click', function() {
            filterOptions.classList.toggle('show');
        });

        /* reset all selected fields and hide filter reset button*/
        $(filterReset).on('click', function() {
            $('#productFilter :checkbox, #productFilter :radio').each(function(){
                $(this).prop('checked', false);
            });
            checkFields();
        });

        /* on selection check if filter reset button should shown or not */
        $(filterForm).on('click', function() {
            checkFields();
        });

    }

  /* productOverview */

    if($('.load')) {

        $('.load').each(function(){
            $(this).show();
            $(this).next('.group').find('a, dl').addClass('hide');
        });

        $('a.buttonLoad').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.products, .accordion').find('.hide').toggle();
            $(this).toggleClass('open');
        });

        $('.singleProduct').on('click', function(e) {
            window.history.replaceState(null, '', '#' + $(this).attr('id'));
        });

        if( window.location.hash !== '' ) {
            var element = $(window.location.hash);
            element.addClass('pulsate-fwd');

            if( !element.parent().is(':nth-child(2)') ) {
                element.closest('.products').find('a.buttonLoad').trigger('click');
            }
        }
    }

    $(".accordion dd").hide();

    $('.accordion dt').on('click', function() {
      $(this).toggleClass('open');
      $(this).next('dd').slideToggle();
    });

    /* form submit tracking */

    document.addEventListener( 'wpcf7mailsent', function( event ) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'formSubmit'
        });
    }, false );

    /*
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw-netElektra.js', {scope: '/'}).then(function (registration) {
            // Registrierung erfolgreich
            console.log('Service worker registry successfull. Scope is ' + registration.scope);
        }).catch(function (error) {
            // Registrierung fehlgeschlagen
            console.log('Service worker registry failed with ' + error);
        });
    };
    */

});
